




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ForceLogin extends Vue {
  @Prop() public to!: string;

  async created() {
    await this.$auth.loginWithRedirect({ appState: { targetUrl: this.to } });
  }
}
